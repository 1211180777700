import { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { createGlobalStyle } from 'styled-components'

import { useTranslation } from '@i18n'
import useDownloadAppDynamicLink from '@hooks/useDynamicLinkForDownloadApp'
import Button, { ButtonSize } from '@components/DesignSystem/Button'
import CloseIcon from '@components/Icons/CloseIcon'
import BeansIcon from './Icons/BeansIcon'
import { trackOpenApp } from '@libs/eventTracking/downloadBar/trackOpenApp'
import getConfig from 'next/config'
import { trackWebHeaderEnterECPromotionBanner } from '@libs/eventTracking/header'
import { PageName } from '@app-core/eventTracking/PageName'

const { publicRuntimeConfig } = getConfig()

type HeightVariableStyleProps = {
  isVisible: boolean
}

const HeightVariableStyle = createGlobalStyle<HeightVariableStyleProps>`
  :root {
    --download-bar-height: ${(props) => (props.isVisible ? 60 : 0)}px;
  }
`

const Container = styled.div`
  ${tw`bg-primary-indigo bg-opacity-98 text-white`};
  ${tw`sticky top-0 z-modal md:hidden`};

  backdrop-filter: blur(4px);
  padding: 10px 16px 8px 12px;
  height: var(--download-bar-height);
  display: grid;
  grid-template:
    'close slogan href' auto
    '_ logo href' auto / auto 1fr auto;
  align-items: center;
  grid-gap: 2px 8px;
`

const StyledButton = styled(Button)`
  ${tw`text-primary-indigo`};
  grid-area: href;
`

const Slogan = styled.div`
  font-size: 18px;
  grid-area: slogan;
  line-height: 24px;
  font-weight: bold;
`

const Popdaily = styled.div`
  font-size: 12px;
  grid-area: logo;
  line-height: 16px;
`

const StyledBeansIcon = styled(BeansIcon)`
  position: absolute;
  right: 80px;
  bottom: 0;
  z-index: -1;
`

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  padding: 13px;
  margin: -13px;
`

function Bar({ onClose, slogan, subSlogan, button: { text, url, onClick } }) {
  return (
    <Container>
      <StyledBeansIcon />
      <CloseButton onClick={onClose}>
        <CloseIcon width="14" height="14" />
      </CloseButton>
      <Slogan>{slogan}</Slogan>
      <Popdaily>{subSlogan}</Popdaily>
      <StyledButton size={ButtonSize.medium} as="a" href={url} onClick={onClick}>
        {text}
      </StyledButton>
    </Container>
  )
}

const LinePopSelectBarButtonUrl = 'https://poplink.popdaily.com.tw/mwehbig'

function DownloadBar() {
  /**
   * Max:
   *   如果 linePopSelectBannerEnabled 為 true
   *   先顯示 PopSelectBar ，關閉後再顯示 DownloadBar
   *   所以 isLinePopSelectVisible 為 true ， isVisible 為 false
   *
   *   如果 linePopSelectBannerEnabled 為 false
   *   直接顯示 DownloadBar 就好， isVisible 為 true
   */
  const [isVisible, setIsVisible] = useState(!publicRuntimeConfig.linePopSelectBannerEnabled)
  const [isLinePopSelectVisible, setIsLinePopSelectVisible] = useState(
    publicRuntimeConfig.linePopSelectBannerEnabled,
  )
  const href = useDownloadAppDynamicLink()
  const { t } = useTranslation('downloadBar')
  const sloganLength: number = t(`slogan.length`) as any
  const sloganIndex = Math.floor(Date.now() / (60 * 60 * 24 * 7 * 1000)) % sloganLength
  const slogan = t(`slogan.${sloganIndex}`)

  const onClick = () => trackOpenApp(slogan)

  const handleCloseLinePopSelect = () => {
    setIsLinePopSelectVisible(false)
    setIsVisible(true)
  }

  const handleLinePopSelectButtonOnClick = (e) => {
    e.preventDefault()
    trackWebHeaderEnterECPromotionBanner({
      currentPage: {
        pageName: PageName.ARTICLE,
        pageUrl: window.location.href,
      },
      toPage: {
        pageUrl: LinePopSelectBarButtonUrl,
      },
    })
    window.open(e.target.href)
  }

  return (
    <>
      <HeightVariableStyle isVisible={isLinePopSelectVisible || isVisible} />
      {isLinePopSelectVisible && (
        <Bar
          slogan="上萬女孩的話題中心❤"
          onClose={handleCloseLinePopSelect}
          subSlogan="PopDaily 波波黛莉的異想世界"
          button={{
            text: '開啟IG',
            onClick: handleLinePopSelectButtonOnClick,
            url: LinePopSelectBarButtonUrl,
          }}
        />
      )}
      {isVisible && (
        <Bar
          slogan={slogan}
          onClose={() => setIsVisible(false)}
          subSlogan={t('popdaily')}
          button={{
            text: t('openApp'),
            onClick: onClick,
            url: href,
          }}
        />
      )}
    </>
  )
}

export default DownloadBar
