import React from 'react'
import dynamic from 'next/dynamic'

import { useAmp } from 'next/amp'
import { ToastContainer, cssTransition } from 'react-toastify'
import Header from '@components/Header'
import DownloadBar from '@components/DownloadBar'
import ADBlock from '@features/post/components/AMP/ADBlock'

import 'react-toastify/dist/ReactToastify.css'
import styles from './index.scss'
import TopAd from '@components/Ads/TopAd'

const Buoy = dynamic(() => import('@components/Buoy'), { ssr: false })
const SharePopContainer = dynamic(() => import('@components/SharePop'), { ssr: false })
const MessageDialogContainer = dynamic(() => import('@components/MessageDialog'), { ssr: false })
const ConfirmDialogContainer = dynamic(() => import('@components/ConfirmDialog'), { ssr: false })

const Zoom = cssTransition({
  enter: 'toast__zoomIn',
  exit: 'toast__zoomOut',
  duration: 300,
})

const nullThunk = () => null

function LayoutContent(props) {
  const isAmp = useAmp()
  const {
    isApp = false,
    isArticlePage = false,
    children,
    renderBottomBar = nullThunk,
    renderOverLay = nullThunk,
    renderWebHeader,
    hasNavigation,
    location,
    className = 'layout-content',
    noTopAd,
    eventCategories = [],
  } = props

  const topAd = isAmp ? <ADBlock slot="/90969475/popdaily_all_300x250_amp_T" /> : <TopAd />

  return (
    <div className={className}>
      {!isApp && <DownloadBar />}
      <Header
        isApp={isApp}
        isArticlePage={isArticlePage}
        renderWebHeader={renderWebHeader}
        hasNavigation={hasNavigation}
        location={location}
        eventCategories={eventCategories}
      />
      <style jsx>{styles}</style>
      {/* 刊頭廣告區塊 */}
      {!noTopAd && !isApp && topAd}
      {renderOverLay()}
      <main>
        <div className="content">{children}</div>
        <SharePopContainer />
        <MessageDialogContainer />
      </main>
      {renderBottomBar()}
      {!isApp && !isAmp && <Buoy />}
      <ToastContainer
        position="bottom-center"
        autoClose={1300}
        transition={Zoom}
        hideProgressBar={true}
        closeButton={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        className={'toastStyle'}
        bodyClassName={'toastBodyStyle'}
      />
      <ConfirmDialogContainer />
    </div>
  )
}

export default LayoutContent
