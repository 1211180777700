import { SVGProps } from 'react'

function BeansIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="75"
      height="66"
      viewBox="0 0 75 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M70.6197 33.032C69.6495 31.9517 68.0314 31.8884 66.9655 32.8817L56.8039 42.4448C55.738 43.4381 55.6677 45.0861 56.6379 46.1664C57.6081 47.2468 59.2262 47.3101 60.2921 46.3168L70.4537 36.7537C71.5196 35.7604 71.5899 34.1124 70.6197 33.032Z"
          fill="white"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
        <path
          d="M68.2854 33.7256C68.2001 33.7492 68.146 33.7177 68.0607 33.7413C67.9754 33.765 67.8361 33.7571 67.8048 33.8123C67.7195 33.8359 67.603 33.9147 67.5717 33.9699C67.4552 34.0488 67.4239 34.1039 67.4155 34.2459C67.353 34.3563 67.3757 34.443 67.3132 34.5534C67.282 34.6086 67.3048 34.6953 67.2735 34.7505C67.3191 34.924 67.3419 35.0107 67.504 35.1053C67.5581 35.1369 67.6121 35.1684 67.6662 35.2C67.8283 35.2946 67.9364 35.3577 68.0757 35.3656C68.161 35.342 68.2463 35.3183 68.3004 35.3499C68.3857 35.3262 68.471 35.3026 68.5563 35.279C68.6728 35.2001 68.7581 35.1765 68.8747 35.0977C68.9912 35.0188 69.0225 34.9636 69.0309 34.8217C69.0622 34.7665 69.0707 34.6246 69.0479 34.5378C69.0791 34.4827 69.0563 34.3959 69.0335 34.3092C69.0108 34.2224 68.9339 34.1042 68.8571 33.9859C68.803 33.9543 68.749 33.9228 68.6949 33.8912C68.5328 33.7966 68.4247 33.7335 68.2854 33.7256Z"
          fill="#6567FF"
        />
        <path
          d="M65.9869 38.0386C65.9159 38.2909 65.899 38.5747 65.828 38.827C65.7655 38.9374 65.7883 39.0242 65.8111 39.1109C65.8339 39.1976 65.8567 39.2844 65.9335 39.4027C65.9563 39.4894 66.0644 39.5525 66.1725 39.6156C66.2806 39.6787 66.3346 39.7102 66.4427 39.7733C66.4967 39.8049 66.582 39.7812 66.6361 39.8128C66.7754 39.8207 66.892 39.7418 66.9773 39.7182C67.0085 39.663 67.0938 39.6394 67.1251 39.5842C67.2416 39.5053 67.3041 39.395 67.3439 39.1978C67.4148 38.9455 67.4318 38.6617 67.5027 38.4094C67.5652 38.299 67.5425 38.2122 67.5197 38.1255C67.5281 37.9836 67.4513 37.8653 67.3973 37.8337C67.3745 37.747 67.2976 37.6287 67.1895 37.5656C67.0814 37.5025 67.0274 37.471 66.9193 37.4079C66.8653 37.3763 66.78 37.4 66.7259 37.3684C66.5866 37.3605 66.47 37.4394 66.3847 37.463C66.3535 37.5182 66.2682 37.5418 66.2369 37.597C66.0891 37.7311 66.0266 37.8414 65.9869 38.0386Z"
          fill="#6567FF"
        />
        <path
          d="M65.0161 35.5626C64.7915 35.5783 64.6522 35.5704 64.4588 35.5309C64.3735 35.5546 64.2341 35.5467 64.1488 35.5703C64.0635 35.594 63.947 35.6728 63.9157 35.728C63.7992 35.8068 63.7679 35.862 63.7594 36.0039C63.6969 36.1143 63.7197 36.2011 63.6572 36.3114C63.68 36.3982 63.6488 36.4534 63.6715 36.5401C63.7171 36.7136 63.7399 36.8003 63.902 36.895C63.9561 36.9265 64.0101 36.9581 64.0642 36.9896C64.1723 37.0527 64.3116 37.0606 64.451 37.0685C64.6443 37.108 64.8149 37.0607 65.0083 37.1001C65.0936 37.0765 65.233 37.0844 65.3183 37.0607C65.4036 37.0371 65.5201 36.9583 65.5514 36.9031C65.6679 36.8242 65.6992 36.7691 65.7077 36.6271C65.7702 36.5167 65.7474 36.43 65.8099 36.3196C65.8099 36.3196 65.7871 36.2329 65.7643 36.1461C65.7187 35.9727 65.6959 35.8859 65.5338 35.7913C65.4797 35.7597 65.4257 35.7282 65.3717 35.6966C65.2636 35.6336 65.1555 35.5705 65.0161 35.5626Z"
          fill="#6567FF"
        />
        <path
          d="M59.9085 41.0262C59.8916 41.31 59.8434 41.6491 59.8265 41.9329C59.8493 42.0197 59.8408 42.1616 59.8636 42.2483C59.8864 42.3351 59.9633 42.4534 60.0173 42.4849C60.0941 42.6032 60.1482 42.6347 60.2875 42.6427C60.3956 42.7057 60.4809 42.6821 60.589 42.7452C60.8136 42.7295 60.9842 42.6822 61.132 42.5482C61.1633 42.493 61.1946 42.4378 61.2258 42.3826C61.2883 42.2722 61.2968 42.1303 61.3052 41.9883C61.3222 41.7045 61.3704 41.3654 61.3873 41.0816C61.3645 40.9948 61.373 40.8529 61.3502 40.7662C61.3274 40.6794 61.2505 40.5612 61.1965 40.5296C61.1197 40.4113 61.0656 40.3798 60.9263 40.3719C60.8182 40.3088 60.7329 40.3324 60.6248 40.2693C60.4002 40.2851 60.2296 40.3323 60.0817 40.4664C60.0505 40.5216 60.0192 40.5767 59.988 40.6319C59.9255 40.7423 59.917 40.8842 59.9085 41.0262Z"
          fill="#6567FF"
        />
        <path
          d="M64.2588 39.8202C64.182 39.7019 64.0511 39.5521 63.9742 39.4338C63.8974 39.3155 63.8434 39.284 63.7353 39.2209C63.6584 39.1026 63.6044 39.071 63.465 39.0631C63.357 39 63.2717 39.0237 63.1636 38.9606C63.0783 38.9842 62.9389 38.9763 62.8536 39C62.7683 39.0236 62.6518 39.1024 62.6205 39.1576C62.5893 39.2128 62.558 39.268 62.5268 39.3232C62.4642 39.4336 62.4558 39.5755 62.4473 39.7174C62.4701 39.8042 62.4389 39.8594 62.4616 39.9461C62.5072 40.1196 62.53 40.2063 62.6922 40.301C62.8002 40.3641 62.8771 40.4824 62.9539 40.6006C62.8999 40.5691 62.8771 40.4823 62.823 40.4508C62.8458 40.5375 62.8999 40.5691 62.9227 40.6558C62.9454 40.7426 63.0535 40.8057 63.1616 40.8688C63.2697 40.9318 63.3238 40.9634 63.4319 41.0265C63.5399 41.0896 63.6252 41.0659 63.7105 41.0423C63.7958 41.0187 63.8811 40.995 63.9977 40.9162C64.0289 40.861 64.1142 40.8373 64.1455 40.7822C64.2621 40.7033 64.3246 40.5929 64.3643 40.3958C64.3955 40.3406 64.3727 40.2539 64.404 40.1987C64.4125 40.0568 64.3356 39.9385 64.2588 39.8202Z"
          fill="#6567FF"
        />
        <path
          d="M59.6026 43.3442C59.2074 43.4073 58.7894 43.3836 58.3713 43.3598C58.4254 43.3914 58.5107 43.3678 58.5647 43.3993C58.4566 43.3362 58.3713 43.3598 58.286 43.3835C58.2007 43.4071 58.1154 43.4308 57.9989 43.5096C57.9136 43.5333 57.8511 43.6436 57.7886 43.754C57.726 43.8644 57.6948 43.9196 57.6323 44.03C57.5926 44.2271 57.6069 44.4558 57.7378 44.6056C57.7918 44.6371 57.8146 44.7239 57.8687 44.7554C57.9455 44.8737 58.0536 44.9368 58.247 44.9762C58.5257 44.9921 58.7731 45.0631 59.083 45.0237C59.3077 45.0079 59.5011 45.0474 59.7257 45.0317C59.811 45.008 59.9503 45.0159 60.0356 44.9923C60.1209 44.9686 60.2375 44.8898 60.2687 44.8346C60.3853 44.7558 60.4166 44.7006 60.425 44.5587C60.4875 44.4483 60.4647 44.3615 60.5272 44.2512C60.5129 44.0225 60.4673 43.849 60.3365 43.6992C60.0434 43.4547 59.8273 43.3285 59.6026 43.3442Z"
          fill="#6567FF"
        />
        <path
          d="M59.0816 42.2324C59.0901 42.0904 59.0673 42.0037 59.1298 41.8933C59.107 41.8066 59.1154 41.6647 59.0927 41.5779C59.0699 41.4912 58.993 41.3729 58.939 41.3413C58.8621 41.2231 58.8081 41.1915 58.6688 41.1836C58.5607 41.1205 58.4754 41.1442 58.3673 41.0811C58.282 41.1047 58.2279 41.0732 58.1426 41.0968C57.972 41.1441 57.8867 41.1677 57.793 41.3333C57.7617 41.3885 57.7305 41.4437 57.6992 41.4989C57.6367 41.6092 57.6282 41.7512 57.6198 41.8931C57.6113 42.035 57.6341 42.1218 57.5716 42.2322C57.5944 42.3189 57.5859 42.4608 57.6087 42.5476C57.6315 42.6343 57.7083 42.7526 57.7624 42.7841C57.8392 42.9024 57.8933 42.934 58.0326 42.9419C58.1407 43.005 58.226 42.9813 58.3341 43.0444C58.4194 43.0208 58.4734 43.0523 58.5587 43.0287C58.7293 42.9814 58.8146 42.9577 58.9084 42.7922C58.9396 42.737 58.9709 42.6818 59.0022 42.6266C59.0106 42.4847 59.0731 42.3743 59.0816 42.2324Z"
          fill="#6567FF"
        />
        <path
          d="M72.3277 36.7462C72.1994 36.0838 71.403 35.8393 70.9284 36.2965L59.8487 46.7189C59.374 47.1762 59.5479 48.012 60.1821 48.1619C62.1615 48.7299 65.4283 47.4057 68.3304 44.6937C71.2012 42.0368 72.7353 38.82 72.3277 36.7462Z"
          fill="#AEB6FF"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
        <path
          d="M67.1068 30.9815C65.1273 30.4135 61.8606 31.7377 58.9585 34.4497C56.1417 37.1381 54.5764 40.4102 54.984 42.484C55.1123 43.1463 55.9086 43.3909 56.3833 42.9336L67.463 32.5112C67.8836 32.0224 67.7098 31.1866 67.1068 30.9815Z"
          fill="#AEB6FF"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
        <path
          d="M24.1212 29.6823C23.151 28.602 21.5329 28.5387 20.467 29.532L10.3282 39.1818C9.2623 40.1751 9.19199 41.8231 10.1622 42.9035C11.1324 43.9839 12.7505 44.0472 13.8164 43.0538L23.978 33.4908C25.0211 32.4107 25.0914 30.7627 24.1212 29.6823Z"
          fill="white"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
        <path
          d="M21.8096 30.4625C21.7243 30.4861 21.6703 30.4546 21.585 30.4782C21.4997 30.5019 21.3603 30.4939 21.275 30.5176C21.1897 30.5412 21.0732 30.6201 21.0419 30.6753C20.9254 30.7541 20.8941 30.8093 20.8857 30.9512C20.8231 31.0616 20.8459 31.1483 20.7834 31.2587C20.8062 31.3454 20.775 31.4006 20.7978 31.4874C20.8433 31.6609 20.8661 31.7476 21.0283 31.8422C21.0823 31.8738 21.1364 31.9053 21.1904 31.9369C21.2985 32 21.4378 32.0079 21.5772 32.0158C21.6625 31.9921 21.7165 32.0237 21.8018 32C21.8871 31.9764 22.0265 31.9843 22.1118 31.9607C22.1971 31.937 22.3136 31.8582 22.3449 31.803C22.4614 31.7242 22.4927 31.669 22.5011 31.527C22.5636 31.4167 22.5409 31.3299 22.6034 31.2195C22.5806 31.1328 22.6118 31.0776 22.589 30.9909C22.5435 30.8174 22.5207 30.7307 22.3585 30.636C22.3045 30.6045 22.2504 30.5729 22.1964 30.5414C22.0883 30.4783 21.949 30.4704 21.8096 30.4625Z"
          fill="#6567FF"
        />
        <path
          d="M19.4883 34.6884C19.4174 34.9408 19.4004 35.2246 19.3295 35.4769C19.267 35.5873 19.2897 35.674 19.3125 35.7608C19.3353 35.8475 19.3581 35.9343 19.435 36.0525C19.4577 36.1393 19.5658 36.2024 19.6739 36.2655C19.782 36.3286 19.8361 36.3601 19.9441 36.4232C19.9982 36.4547 20.0835 36.4311 20.1375 36.4627C20.2769 36.4706 20.3934 36.3917 20.4787 36.3681C20.51 36.3129 20.5953 36.2892 20.6265 36.2341C20.7431 36.1552 20.8056 36.0448 20.8453 35.8477C20.9163 35.5954 20.9332 35.3116 21.0042 35.0593C21.0667 34.9489 21.0439 34.8621 21.0211 34.7754C20.9983 34.6887 20.9755 34.6019 20.8987 34.4836C20.8759 34.3969 20.7678 34.3338 20.6597 34.2707C20.5516 34.2076 20.4976 34.1761 20.3895 34.113C20.3355 34.0814 20.2502 34.1051 20.1961 34.0735C20.0568 34.0656 19.9402 34.1444 19.8549 34.1681C19.8237 34.2233 19.7384 34.2469 19.7071 34.3021C19.5593 34.4361 19.4968 34.5465 19.4883 34.6884Z"
          fill="#6567FF"
        />
        <path
          d="M18.4863 32.2676C18.2929 32.2282 18.1224 32.2755 17.929 32.236C17.8437 32.2597 17.7043 32.2518 17.619 32.2754C17.5337 32.2991 17.4172 32.3779 17.3859 32.4331C17.2694 32.5119 17.2381 32.5671 17.2296 32.709C17.1671 32.8194 17.1899 32.9061 17.1274 33.0165C17.1502 33.1033 17.119 33.1585 17.1417 33.2452C17.1873 33.4187 17.2101 33.5054 17.3722 33.6001C17.4263 33.6316 17.4803 33.6631 17.5344 33.6947C17.6425 33.7578 17.7818 33.7657 17.9212 33.7736C18.1145 33.813 18.2851 33.7658 18.4785 33.8052C18.5638 33.7816 18.7032 33.7895 18.7885 33.7658C18.8738 33.7422 18.9903 33.6634 19.0216 33.6082C19.1381 33.5293 19.1694 33.4741 19.1778 33.3322C19.2404 33.2218 19.2176 33.1351 19.2801 33.0247C19.2573 32.938 19.2885 32.8828 19.2657 32.796C19.2202 32.6226 19.1974 32.5358 19.0352 32.4412C18.9812 32.4096 18.9272 32.3781 18.8731 32.3466C18.8191 32.315 18.6257 32.2755 18.4863 32.2676Z"
          fill="#6567FF"
        />
        <path
          d="M13.3788 37.7315C13.3618 38.0154 13.3137 38.3544 13.2967 38.6383C13.3195 38.725 13.3111 38.8669 13.3339 38.9537C13.3566 39.0404 13.4335 39.1587 13.4875 39.1902C13.5644 39.3085 13.6184 39.3401 13.7577 39.348C13.8658 39.4111 13.9511 39.3874 14.0592 39.4505C14.2839 39.4348 14.4545 39.3875 14.6023 39.2535C14.6335 39.1983 14.6648 39.1431 14.696 39.0879C14.7586 38.9775 14.767 38.8356 14.7755 38.6937C14.7924 38.4098 14.8406 38.0708 14.8575 37.7869C14.8347 37.7002 14.8432 37.5582 14.8204 37.4715C14.7976 37.3848 14.7208 37.2665 14.6667 37.2349C14.5899 37.1166 14.5358 37.0851 14.3965 37.0772C14.2884 37.0141 14.2031 37.0377 14.095 36.9746C13.8704 36.9904 13.6998 37.0377 13.552 37.1717C13.5207 37.2269 13.4895 37.2821 13.4582 37.3373C13.3957 37.4476 13.3872 37.5896 13.3788 37.7315Z"
          fill="#6567FF"
        />
        <path
          d="M17.8142 36.5018C17.7374 36.3836 17.6065 36.2337 17.5297 36.1154C17.4529 35.9972 17.3448 35.9341 17.2907 35.9025C17.2139 35.7842 17.1598 35.7527 17.0205 35.7448C16.9124 35.6817 16.8271 35.7053 16.719 35.6422C16.6337 35.6659 16.4944 35.658 16.4091 35.6816C16.3238 35.7053 16.2072 35.7841 16.176 35.8393C16.1447 35.8945 16.1135 35.9497 16.0822 36.0048C16.0197 36.1152 16.0112 36.2572 16.0028 36.3991C16.0256 36.4858 15.9943 36.541 16.0171 36.6277C16.0627 36.8012 16.0855 36.888 16.2476 36.9826C16.3557 37.0457 16.4325 37.164 16.5094 37.2823C16.4553 37.2507 16.4325 37.164 16.3785 37.1324C16.4013 37.2192 16.4553 37.2507 16.4781 37.3375C16.5009 37.4242 16.609 37.4873 16.7171 37.5504C16.8252 37.6135 16.8792 37.645 16.9873 37.7081C17.0954 37.7712 17.1807 37.7476 17.266 37.7239C17.3513 37.7003 17.4366 37.6767 17.5531 37.5978C17.5844 37.5426 17.6697 37.519 17.7009 37.4638C17.8175 37.385 17.88 37.2746 17.9197 37.0775C17.951 37.0223 17.9282 36.9355 17.9594 36.8803C17.8826 36.7621 17.8911 36.6201 17.8142 36.5018Z"
          fill="#6567FF"
        />
        <path
          d="M13.1581 40.0259C12.7628 40.0889 12.3448 40.0652 11.9267 40.0415C11.9808 40.073 12.0661 40.0494 12.1201 40.0809C12.012 40.0178 11.9267 40.0415 11.8414 40.0651C11.7561 40.0888 11.6708 40.1124 11.5543 40.1912C11.469 40.2149 11.4065 40.3253 11.344 40.4356C11.2815 40.546 11.2502 40.6012 11.1877 40.7116C11.148 40.9087 11.1623 41.1374 11.2932 41.2872C11.3472 41.3188 11.37 41.4055 11.4241 41.437C11.5009 41.5553 11.609 41.6184 11.8024 41.6579C12.0811 41.6737 12.3285 41.7447 12.6385 41.7053C12.8631 41.6896 13.0565 41.729 13.2811 41.7133C13.3664 41.6896 13.5058 41.6976 13.5911 41.6739C13.6764 41.6503 13.7929 41.5714 13.8242 41.5162C13.9407 41.4374 13.972 41.3822 13.9805 41.2403C14.043 41.1299 14.0202 41.0432 14.0827 40.9328C14.0684 40.7041 14.0228 40.5306 13.8919 40.3808C13.5448 40.1048 13.3827 40.0101 13.1581 40.0259Z"
          fill="#6567FF"
        />
        <path
          d="M12.583 38.8825C12.5915 38.7406 12.5687 38.6538 12.6312 38.5435C12.6084 38.4567 12.6169 38.3148 12.5941 38.2281C12.5713 38.1413 12.4945 38.023 12.4404 37.9915C12.3636 37.8732 12.3096 37.8417 12.1702 37.8338C12.0621 37.7707 11.9768 37.7943 11.8687 37.7312C11.7834 37.7548 11.7294 37.7233 11.6441 37.7469C11.4735 37.7942 11.3882 37.8179 11.2944 37.9834C11.2632 38.0386 11.2319 38.0938 11.2007 38.149C11.1382 38.2594 11.1297 38.4013 11.1212 38.5432C11.1128 38.6852 11.1356 38.7719 11.0731 38.8823C11.0958 38.969 11.0874 39.111 11.1102 39.1977C11.133 39.2844 11.2098 39.4027 11.2638 39.4343C11.3407 39.5525 11.3947 39.5841 11.5341 39.592C11.6422 39.6551 11.7275 39.6315 11.8355 39.6945C11.9208 39.6709 11.9749 39.7025 12.0602 39.6788C12.2308 39.6315 12.3161 39.6079 12.4098 39.4423C12.4411 39.3871 12.4724 39.3319 12.5036 39.2767C12.5349 39.2216 12.5746 39.0244 12.583 38.8825Z"
          fill="#6567FF"
        />
        <path
          d="M25.8291 33.3963C25.7009 32.734 24.9045 32.4894 24.4298 32.9467L13.3501 43.3691C12.8754 43.8263 13.0493 44.6622 13.6835 44.8121C15.663 45.3801 18.9297 44.0558 21.8319 41.3438C24.6715 38.7422 26.2368 35.4701 25.8291 33.3963Z"
          fill="#AEB6FF"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
        <path
          d="M20.6311 27.7183C18.6517 27.1503 15.3849 28.4745 12.4828 31.1866C9.666 33.8749 8.10069 37.147 8.50832 39.2208C8.6366 39.8831 9.43294 40.1277 9.90762 39.6704L20.9873 29.2481C21.3852 28.6725 21.2653 27.8682 20.6311 27.7183Z"
          fill="#AEB6FF"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
        <path
          d="M34.3 50.3108C32.8753 50.287 31.7157 51.4459 31.6851 52.8967L31.4899 66.9556C31.4593 68.4064 32.5916 69.5815 34.0163 69.6053C35.441 69.6292 36.6007 68.4703 36.6312 67.0194L36.8805 52.9921C36.8571 51.5097 35.7247 50.3347 34.3 50.3108Z"
          fill="white"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
        <path
          d="M33.2432 52.5578C33.2119 52.613 33.1266 52.6366 33.0954 52.6918C32.9788 52.7706 32.9476 52.8258 32.9391 52.9678C32.8766 53.0781 32.8994 53.1649 32.8369 53.2753C32.8597 53.362 32.8512 53.5039 32.874 53.5907C32.8968 53.6774 32.9736 53.7957 33.0277 53.8272C33.0817 53.8588 33.1357 53.8903 33.1898 53.9219C33.2979 53.985 33.4372 53.9929 33.5766 54.0008C33.6619 53.9771 33.7159 54.0087 33.8012 53.985C33.9718 53.9377 34.0571 53.9141 34.1509 53.7485C34.1821 53.6933 34.2674 53.6697 34.2987 53.6145C34.4152 53.5357 34.4465 53.4805 34.455 53.3386C34.5175 53.2282 34.4947 53.1414 34.5572 53.0311C34.5344 52.9443 34.5428 52.8024 34.5201 52.7157C34.4973 52.6289 34.4204 52.5106 34.3664 52.4791C34.3123 52.4475 34.2583 52.416 34.2043 52.3844C34.0962 52.3213 33.9568 52.3134 33.8175 52.3055C33.7322 52.3292 33.6781 52.2976 33.5928 52.3213C33.4222 52.3686 33.3057 52.4474 33.2432 52.5578Z"
          fill="#6567FF"
        />
        <path
          d="M34.6445 57.1946C34.7982 57.4311 34.9518 57.6677 35.1368 57.8491C35.1596 57.9358 35.2677 57.9989 35.3757 58.062C35.4838 58.1251 35.5379 58.1566 35.646 58.2197C35.7541 58.2828 35.8394 58.2592 35.9247 58.2355C36.01 58.2119 36.0952 58.1883 36.2118 58.1094C36.2431 58.0542 36.3284 58.0306 36.3596 57.9754C36.4762 57.8966 36.5387 57.7862 36.5784 57.5891C36.6096 57.5339 36.5869 57.4471 36.6181 57.392C36.6266 57.25 36.5497 57.1317 36.5269 57.045C36.3733 56.8084 36.2196 56.5719 36.0347 56.3905C36.0119 56.3037 35.9038 56.2407 35.7957 56.1776C35.6876 56.1145 35.6336 56.0829 35.5255 56.0198C35.4174 55.9567 35.3321 55.9804 35.2468 56.004C35.1615 56.0277 35.0762 56.0513 34.9596 56.1301C34.9284 56.1853 34.8431 56.209 34.8118 56.2642C34.6953 56.343 34.6328 56.4534 34.5931 56.6505C34.5618 56.7057 34.5846 56.7924 34.5533 56.8476C34.5221 56.9028 34.5677 57.0763 34.6445 57.1946Z"
          fill="#6567FF"
        />
        <path
          d="M32.2411 56.2321C32.0933 56.3661 31.9454 56.5001 31.7976 56.6342C31.6811 56.713 31.6498 56.7682 31.6414 56.9101C31.5789 57.0205 31.6017 57.1072 31.5391 57.2176C31.5619 57.3044 31.5535 57.4463 31.5763 57.533C31.599 57.6198 31.6759 57.738 31.7299 57.7696C31.784 57.8011 31.838 57.8327 31.8921 57.8642C32.0002 57.9273 32.1395 57.9352 32.2788 57.9431C32.3641 57.9195 32.4182 57.951 32.5035 57.9274C32.6741 57.8801 32.7594 57.8565 32.8531 57.6909C33.0009 57.5569 33.1488 57.4229 33.2966 57.2888C33.4131 57.21 33.4444 57.1548 33.4528 57.0129C33.5153 56.9025 33.4925 56.8158 33.5551 56.7054C33.5323 56.6186 33.5407 56.4767 33.5179 56.39C33.4951 56.3032 33.4183 56.185 33.3643 56.1534C33.3102 56.1219 33.2562 56.0903 33.2021 56.0588C33.094 55.9957 32.9547 55.9878 32.8154 55.9799C32.7301 56.0035 32.676 55.972 32.5907 55.9956C32.4742 56.0744 32.3889 56.0981 32.2411 56.2321Z"
          fill="#6567FF"
        />
        <path
          d="M32.5745 63.7701C32.8135 63.983 32.9672 64.2196 33.2062 64.4325C33.283 64.5508 33.337 64.5824 33.4764 64.5903C33.5845 64.6534 33.6698 64.6297 33.7779 64.6928C33.8632 64.6692 34.0025 64.6771 34.0878 64.6534C34.1731 64.6298 34.2896 64.551 34.3209 64.4958C34.3522 64.4406 34.3834 64.3854 34.4147 64.3302C34.4772 64.2198 34.4856 64.0779 34.4941 63.936C34.4798 63.7073 34.4342 63.5338 34.3033 63.384C34.0643 63.1711 33.9107 62.9345 33.6717 62.7216C33.5949 62.6033 33.5408 62.5717 33.4015 62.5638C33.2934 62.5007 33.2081 62.5244 33.1 62.4613C33.0147 62.4849 32.8754 62.477 32.7901 62.5007C32.7048 62.5243 32.5882 62.6031 32.5569 62.6583C32.5257 62.7135 32.4944 62.7687 32.4632 62.8239C32.4007 62.9343 32.3922 63.0762 32.3838 63.2181C32.344 63.4152 32.4437 63.6203 32.5745 63.7701Z"
          fill="#6567FF"
        />
        <path
          d="M34.7481 59.6782C34.5547 59.6388 34.4153 59.6309 34.276 59.623C34.1907 59.6466 34.0514 59.6387 33.9973 59.6072C33.912 59.6308 33.7727 59.6229 33.6874 59.6466C33.6021 59.6702 33.4855 59.749 33.4543 59.8042C33.3377 59.8831 33.3065 59.9382 33.298 60.0802C33.2355 60.1906 33.2583 60.2773 33.1958 60.3877C33.2186 60.4744 33.1873 60.5296 33.2101 60.6163C33.2557 60.7898 33.2785 60.8766 33.4406 60.9712C33.4946 61.0027 33.5487 61.0343 33.6027 61.0658C33.7108 61.1289 33.8502 61.1368 33.9895 61.1447C34.1288 61.1526 34.2141 61.129 34.3535 61.1369C34.2994 61.1054 34.2141 61.129 34.1601 61.0975C34.2141 61.129 34.2994 61.1054 34.3535 61.1369C34.4616 61.2 34.5469 61.1764 34.6322 61.1527C34.7175 61.1291 34.8028 61.1054 34.9193 61.0266C35.0274 61.0897 35.144 61.0109 35.2065 60.9005C35.269 60.7901 35.3002 60.7349 35.3628 60.6245C35.394 60.5693 35.3712 60.4826 35.4025 60.4274C35.4109 60.2855 35.3341 60.1672 35.3113 60.0804C35.2573 60.0489 35.2345 59.9622 35.1804 59.9306C35.0496 59.7808 34.9415 59.7177 34.7481 59.6782Z"
          fill="#6567FF"
        />
        <path
          d="M34.0911 65.5368C33.8182 65.8916 33.5226 66.1597 33.227 66.4277C33.1104 66.5065 33.0792 66.5617 33.0707 66.7037C33.0082 66.814 33.031 66.9008 32.9685 67.0112C32.9828 67.2398 33.0284 67.4133 33.1593 67.5631C33.2902 67.713 33.5064 67.8392 33.731 67.8234C33.8163 67.7998 33.8703 67.8313 33.9556 67.8077C34.1262 67.7604 34.2115 67.7368 34.3053 67.5712C34.6009 67.3031 34.9278 66.9799 35.1694 66.6803C35.2859 66.6015 35.3172 66.5463 35.3256 66.4044C35.3881 66.294 35.3653 66.2072 35.4279 66.0969C35.4135 65.8682 35.3679 65.6947 35.2371 65.5449C35.1062 65.395 34.89 65.2689 34.6654 65.2846C34.5801 65.3082 34.526 65.2767 34.4407 65.3003C34.2701 65.3476 34.1536 65.4265 34.0911 65.5368Z"
          fill="#6567FF"
        />
        <path
          d="M32.908 65.2134C32.8312 65.0951 32.7771 65.0635 32.7003 64.9452C32.6235 64.827 32.5694 64.7954 32.4301 64.7875C32.322 64.7244 32.2367 64.7481 32.1286 64.685C32.0433 64.7086 31.904 64.7007 31.8187 64.7243C31.7334 64.748 31.6168 64.8268 31.5856 64.882C31.5543 64.9372 31.523 64.9924 31.4918 65.0476C31.4293 65.158 31.4208 65.2999 31.4124 65.4418C31.4351 65.5286 31.4039 65.5837 31.4267 65.6705C31.4723 65.844 31.4951 65.9307 31.6572 66.0253C31.734 66.1436 31.7881 66.1752 31.8649 66.2935C31.9417 66.4117 31.9958 66.4433 32.1351 66.4512C32.2432 66.5143 32.3285 66.4906 32.4366 66.5537C32.5219 66.5301 32.6612 66.538 32.7465 66.5144C32.7238 66.4276 32.8091 66.404 32.8716 66.2936C32.9028 66.2384 32.9341 66.1832 32.9653 66.128C33.0278 66.0176 33.0363 65.8757 33.0448 65.7338C33.022 65.647 33.0532 65.5919 33.0304 65.5051C33.0076 65.4184 32.9849 65.3316 32.908 65.2134Z"
          fill="#6567FF"
        />
        <path
          d="M38.1339 51.6676C37.5934 51.3521 36.8713 51.7384 36.8603 52.3928L36.6742 67.7054C36.6631 68.3599 37.3657 68.77 37.9172 68.431C39.6883 67.3353 41.0602 64.0238 41.0725 60.0655C41.0849 56.1073 39.8861 52.7639 38.1339 51.6676Z"
          fill="#AEB6FF"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
        <path
          d="M30.4218 51.5718C28.6507 52.6676 27.2788 55.9791 27.2665 59.9373C27.2001 63.8641 28.4529 67.239 30.2051 68.3353C30.7456 68.6507 31.4677 68.2645 31.4787 67.61L31.6648 52.2974C31.6759 51.643 30.9733 51.2328 30.4218 51.5718Z"
          fill="#AEB6FF"
          stroke="#3A3A3A"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M56.3121 17.4134H55.6768V17.2849C55.6768 17.2207 55.6768 17.1564 55.6768 17.0921C55.6768 13.044 55.6768 10.9878 54.724 10.024C53.7711 9.06012 51.7383 8.99586 47.7363 8.99586C47.6728 8.99586 47.6093 8.99586 47.5458 8.99586H47.4187V8.3533H47.5458C47.6093 8.3533 47.6728 8.3533 47.7363 8.3533C51.7383 8.3533 53.7711 8.3533 54.724 7.38946C55.6768 6.42562 55.6768 4.36942 55.6768 0.321281C55.6768 0.257025 55.6768 0.192769 55.6768 0.128512V0H56.3121V0.128512C56.3121 0.192769 56.3121 0.257025 56.3121 0.321281C56.3121 4.36942 56.3121 6.42562 57.2649 7.38946C58.2178 8.3533 60.2505 8.3533 64.2525 8.3533C64.3161 8.3533 64.3796 8.3533 64.4431 8.3533H64.5702V8.99586H64.4431C64.3796 8.99586 64.3161 8.99586 64.2525 8.99586C60.2505 8.99586 58.2178 8.99586 57.2649 9.95971C56.3121 10.9235 56.3121 12.9797 56.3121 17.0279C56.3121 17.0921 56.3121 17.1564 56.3121 17.2207V17.4134ZM53.5805 8.67458C54.2793 8.86735 54.7875 9.12438 55.2322 9.50991C55.6133 9.89545 55.8674 10.4738 56.058 11.1806C56.2485 10.4738 56.5026 9.95971 56.8838 9.50991C57.2649 9.12438 57.8366 8.86735 58.5354 8.67458C57.8366 8.48182 57.3284 8.22479 56.8838 7.83925C56.5026 7.45372 56.2485 6.87541 56.058 6.16859C55.8674 6.87541 55.6133 7.38946 55.2322 7.83925C54.7875 8.28905 54.2793 8.54607 53.5805 8.67458Z"
        fill="white"
        fillOpacity="0.6"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="51.6285"
            height="57.3846"
            fill="white"
            transform="matrix(-0.49277 0.87016 -0.86363 -0.504126 75 35.075)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BeansIcon
