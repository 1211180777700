import { useEffect, useState, useMemo } from 'react'
import pick from 'lodash/pick'
import { publicUrl } from '@libs/appUrl'
import { useRouter } from 'next/router'

/**
 * dynamic link 使用的時候
 * 請使用 <a href={getDynamicLinkForDownloadApp()}>link</a>的方式處理
 * 不然 iOS 在 google chrome 手機瀏覽器，會有無法跳轉至 App Store 的狀況
 */

/**
 *
 * An Old Message From finalevil
 * dynamic link example:
 *   https://link.popdaily.com.tw/?apn=com.popdaily&isi=1410055224&ibi=org.reactjs.native.dev.PopDaily&ius=popdaily&imv=1.1.3&cid=4491936565711324065&_&link=https%3A%2F%2Fwww.popdaily.com.tw%2Fbeauty%2F798460%3Fchannel%3DFacebook%26fanpage%3Dpopcutie%26utm_medium%3Dpost%26format%3D1v3img%26landingpage%3DURL%26date%3D20190214%26to%3Dhttps%3A%2F%2FWebView2%2Fbeauty%2F798460
 *   https://link.popdaily.com.tw/?apn=com.popdaily&isi=1410055224&ibi=org.reactjs.native.dev.PopDaily&ius=popdaily&imv=1.1.3&cid=4491936565711324065&_&link=https%3A%2F%2Fwww.popdaily.com.tw%2Fbeauty%2F798460%3Fchannel%3DFacebook%26fanpage%3Dpopcutie%26utm_medium%3Dpost%26format%3D1v3img%26landingpage%3DURL%26date%3D20190214
 *
 */

const qs = {
  stringify: (query) => {
    const result = Object.entries(query)
      .map((entry) => entry.map(encodeURIComponent).join('='))
      .join('&')

    return result && `?${result}`
  },
}

type UtmQuery = {
  utm_campaign: string
  utm_medium: string
  utm_source: string
  utm_content: string
}

type Query = {
  apn: string
  isi: string
  ibi: string
  efr: number
  link: string
  st?: string
  sd?: string
  si?: string
}

const useDynamicLinkForDownloadApp = (destinationUrl?: string) => {
  const router = useRouter()
  const { asPath } = router
  const originalQuery = router.query as UtmQuery
  const asPathWithoutQuery = asPath.replace(/^([^?#]+).*/, '$1')
  const utmQuery = useMemo(
    () => pick(originalQuery, ['utm_campaign', 'utm_medium', 'utm_source', 'utm_content']),
    [originalQuery],
  )
  const link = destinationUrl || publicUrl(asPathWithoutQuery)
  const [query, setQuery] = useState<Query>({
    apn: 'com.popdaily',
    isi: '1410055224',
    ibi: 'org.reactjs.native.dev.PopDaily',
    efr: 1,
    link: link + qs.stringify(utmQuery),
  })

  const [toAppStore, setToAppStore] = useState<boolean>(false)

  useEffect(() => {
    const { userAgent } = window.navigator
    const { referrer } = window.document
    const isFB = userAgent.includes('FB')

    // FB deep link 壞了
    // 改成直接導 AppStore
    if (userAgent.includes('iPhone') && isFB) {
      return setToAppStore(true)
    }

    if (!utmQuery.utm_source && referrer && !referrer.toLowerCase().includes('popdaily.com.tw')) {
      const nextLink = link + qs.stringify({ ...utmQuery, utm_source: referrer })

      setQuery((query) => ({ ...query, link: nextLink }))
    }

    if (isFB || userAgent.includes('Instagram')) {
      setQuery((query) => ({
        ...query,
        efr: 0,
        st: document.querySelector('meta[property="og:title"]')?.getAttribute('content'),
        sd: '點選下方OPEN按鈕，立刻開始下載App',
        si: document.querySelector('meta[property="og:image"]')?.getAttribute('content'),
      }))
    }
  }, [asPathWithoutQuery, utmQuery, setQuery, destinationUrl, link])

  if (toAppStore) {
    return 'https://apps.apple.com/tw/app/popdaily-%E6%B3%A2%E6%B3%A2%E9%BB%9B%E8%8E%89%E7%9A%84%E7%95%B0%E6%83%B3%E4%B8%96%E7%95%8C/id1410055224'
  }

  return `https://link.popdaily.com.tw/${qs.stringify(query)}`
}

export default useDynamicLinkForDownloadApp
