import StaticContent from '@components/StaticContent'
import tw, { styled, theme } from 'twin.macro'
import cx from 'classnames'

/**
 * 刊頭廣告
 *
 * Ad unit = `BT_a_H`
 */
const StyledStaticContent = styled(StaticContent)`
  ${tw`bg-gray-30`}
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${theme`screens.md`}) {
    height: 250px;
    margin-bottom: 10px;
  }

  iframe {
    margin: 0 auto;
  }
`

export default function TopAd(props) {
  return <StyledStaticContent className={cx('Zi_ad_a_H', props.className)} />
}
