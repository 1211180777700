import { getEventTracker } from '@libs/eventTracking'
import { getCurrentPagePropertyFor } from '@libs/eventTracking/eventPropertyUtils'

/**
 * [Link to spec](https://github.com/popdaily/popdaily.tracking/blob/8cba4fec65665ea862a1042eeace38029e640519/tracking_events/events/web-header/web-header.app-promotion-banner.open-app.md)
 */
export function trackOpenApp(slogan: string) {
  getEventTracker().track({
    name: 'web-header.app-promotion-banner.open-app',
    properties: {
      slogan,
      currentPage: getCurrentPagePropertyFor('', undefined, 'app-promotion-banner'),
    },
  })
}
