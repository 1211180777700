import css from 'styled-jsx/css';

export default css`.layout-content {
  background-color: #ffffff;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box; }
  .layout-content .content {
    width: 100%;
    flex: 1;
    box-sizing: border-box; }
    @media only screen and (max-width: 500px) {
      .layout-content .content {
        padding-top: 0; } }
    .layout-content .content :global(.ad) {
      padding: 0;
      margin: 0 auto;
      box-sizing: border-box; }
      .layout-content .content :global(.ad):empty {
        display: none; }
    .layout-content .content :global(.content__block) {
      margin-right: auto;
      margin-left: auto; }
      @media (min-width: 576px) {
        .layout-content .content :global(.content__block) {
          max-width: 576px; } }
      @media (min-width: 768px) {
        .layout-content .content :global(.content__block) {
          max-width: 768px; } }
      @media (min-width: 992px) {
        .layout-content .content :global(.content__block) {
          max-width: 992px; } }
      @media (min-width: 1216px) {
        .layout-content .content :global(.content__block) {
          max-width: 1216px; } }
    .layout-content .content :global(.content__block.content__block--fullWidth) :global(> div) {
      background-color: #fff;
      margin-bottom: 15px; }
    .layout-content .content :global(.content__block.content__block--radius) {
      padding-right: 15px;
      padding-left: 15px; }
      .layout-content .content :global(.content__block.content__block--radius) :global(> div) {
        border-radius: 4px;
        margin-bottom: 15px;
        background: #fff; }
  .layout-content :global(.Toastify__toast) {
    padding: 14px 12px;
    border-radius: 8px;
    overflow: hidden;
    min-height: unset;
    color: #fff; }
    .layout-content :global(.Toastify__toast)::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
  .layout-content :global(.Toastify__toast--default) {
    background-color: rgba(51, 51, 51, 0.98); }
    .layout-content :global(.Toastify__toast--default)::before {
      background-image: url("https://static.popdaily.com.tw/statics/images/toast/default.png"); }
  .layout-content :global(.Toastify__toast--success) {
    background-color: rgba(0, 209, 181, 0.98); }
    .layout-content :global(.Toastify__toast--success)::before {
      background-image: url("https://static.popdaily.com.tw/statics/images/toast/success.png"); }
  .layout-content :global(.Toastify__toast--error) {
    background-color: rgba(234, 98, 98, 0.98); }
    .layout-content :global(.Toastify__toast--error)::before {
      background-image: url("https://static.popdaily.com.tw/statics/images/toast/error.png"); }
  .layout-content :global(.toastStyle) {
    bottom: 80px;
    padding: 0 16px; }
  .layout-content :global(.toastBodyStyle) {
    font-family: 'GenJyuuGothic', 'Microsoft YaHei', 'Microsoft JhengHei', 'Helvetica', 'Arial', sans-serif;
    line-height: 20px;
    font-size: 14px; }
  .layout-content :global(.toast__zoomIn) {
    animation-name: zoomIn; }

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8); }
  50% {
    opacity: 1; } }
  .layout-content :global(.toast__zoomOut) {
    animation-name: zoomOut; }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8); }
  to {
    opacity: 0; } }
  .layout-content :global(.loadStop) {
    text-align: center;
    color: #999;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 100px;
    padding-bottom: calc(100px + constant(safe-area-inset-bottom));
    padding-bottom: calc(100px + env(safe-area-inset-bottom)); }

:global(body) :global(*) {
  box-sizing: border-box; }

:global(.httpStatusError) {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 16px 0; }
  :global(.httpStatusError) :global(img) {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto; }
    @media only screen and (max-width: 600px) {
      :global(.httpStatusError) :global(img) {
        min-width: 350px; } }
    @media only screen and (min-width: 768px) {
      :global(.httpStatusError) :global(img) {
        min-width: 400px; } }
    @media only screen and (min-width: 992px) {
      :global(.httpStatusError) :global(img) {
        min-width: 450px; } }
    @media only screen and (min-width: 1200px) {
      :global(.httpStatusError) :global(img) {
        min-width: 500px; } }
  :global(.httpStatusError) :global(p) {
    font-family: "Noto sans", "Roboto", "Helvetica Neue", Helvetica, Arial, "Noto Sans TC", "Microsoft JhengHei", "PingFang TC", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #414141;
    margin-top: 16px; }
    :global(.httpStatusError) :global(p):empty {
      display: none; }
`