import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

type Props = {
  slot: string
}

function ADBlock(props: Props) {
  const { slot, ...otherProps } = props

  return (
    <Container>
      <amp-ad
        width="336"
        height="280"
        type="doubleclick"
        layout="fixed"
        data-slot={slot}
        data-multi-size="300x250"
        data-multi-size-validation="false"
        {...otherProps}
      />
    </Container>
  )
}

export default ADBlock
